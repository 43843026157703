<template>
  <div>
    <h3 class="py-3">Targeting</h3>
    <v-divider></v-divider>

    <add-new-targeting-rule v-if="carriers && mobile_os && phone_dataset && mobile_devices" @create="setCreate($event)"
      class="mt-5" :single_target="single_target" @update="updateTargetingRule($event)" :carriers="carriers"
      :mobile_os="mobile_os" :phone_dataset="phone_dataset" :mobile_devices="mobile_devices" :appCampaign="appCampaign"
      :loading="loading" />

  </div>
</template>

<script>

import AddNewTargetingRule from "../../../components/Programmatic/AddNewTargetingRule.vue";

export default {
  data: () => ({
    items: [
      {
        text: "Targeting",
        disabled: false,
        href: "#/tradedesk/targeting",
      },
      {
        text: "Add/ Edit Audience",
        disabled: true,
        href: "#",
      },
    ],
    error: null,
    loading: false,
    appCampaign: false,
  }),
  components: {
    AddNewTargetingRule,
  },
  created() {
    this.getStaticDspData();
  },
  computed: {
    carriers() {
      return this.$store.state.dsp.static_dsp_data.carriers;
    },
    mobile_os() {
      return this.$store.state.dsp.static_dsp_data.mobile_os;
    },
    mobile_devices() {
      return this.$store.state.dsp.static_dsp_data.mobile_devices;
    },
    phone_dataset() {
      return this.$store.state.dsp.static_dsp_data.phone_dataset;
    },
    dsp_targets() {
      return this.$store.state.dsp.dsp_targets;
    },
    single_target() {
      return this.$store.state.dsp.single_target;
    },
  },
  async mounted() {
    if (
      this.$route.name === "add-opera-audience" ||
      this.$route.name === "add-truecaller-audience" ||
      this.$route.name === "add-transsion-audience" ||
      this.$route.name === "add-phoenix-audience" ||
      this.$route.name === "add-ctv-audience" ||
      this.$route.name === "add-gaming-audience"
    ) {
      this.appCampaign = true;
    }

    if (this.$route.name === "edit-targeting-rules") {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch(
          "dsp/singleTargetRule",
          this.$route.params.id
        );
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    }
  },
  methods: {
    async getStaticDspData() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getStaticDspData");
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
    async setCreate(body) {
      if (
        this.$route.name === "add-opera-audience" ||
        this.$route.name === "add-truecaller-audience" ||
        this.$route.name === "add-transsion-audience" ||
        this.$route.name === "add-phoenix-audience" ||
        this.$route.name === "add-ctv-audience" ||
        this.$route.name === "add-gaming-audience"
      ) {
        let app = this.$route.name.split("add-")[1].split("-audience")[0];
        let req_body = {
          app: app,
          body: body,
        };
        //Campaigns
        this.loading = true;
        try {

          await this.$store.dispatch(
            "dsp/createAppCampaignTargetRule",
            req_body
          );

          this.$notify({
            title: "Target created successfully!",
            group: "success",
          });
          this.$router.push(`/${app}/audiences`);
        } catch (error) {
          this.error = error;
        } finally {
          this.loading = false;
        }
      } else {
        this.loading = true;
        try {

          await this.$store.dispatch("dsp/createTargetRule", body);

          this.$notify({
            title: "Target created successfully!",
            group: "success",
          });

          this.$router.push("/tradedesk/targeting");
        } catch (error) {
          this.error = error;
        } finally {
          this.loading = false;
        }
      }
    },

    async updateTargetingRule(body) {
      let req_body = {
        target_Id: this.$route.params.id,
        body: body,
      };
      
      this.loading = true;
      try {

        await this.$store.dispatch("dsp/updateTargetRule", req_body);

        this.$notify({
          title: "Target rule updated successfully!",
          group: "success",
        });

        await this.$router.push({
          name: "programmatic-targeting",
        });
      } catch (error) {
        this.error = error;

      } finally {
        this.loading = false;
      }
    },
  },
};
</script>